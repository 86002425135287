define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _decorators, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("replyChannel.users.[]"), _dec2 = (0, _decorators.default)("whisperChannel.users.[]"), _dec3 = (0, _decorators.default)("topic.id"), _dec4 = (0, _decorators.default)("topic.id"), _dec5 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    topic: null,
    presence: (0, _service.inject)(),
    replyChannel: null,
    whisperChannel: null,
    replyUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    },
    whisperUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    },
    users: (0, _computed.union)("replyUsers", "whisperUsers"),
    replyChannelName(id) {
      return `/discourse-presence/reply/${id}`;
    },
    whisperChannelName(id) {
      return `/discourse-presence/whisper/${id}`;
    },
    shouldDisplay: (0, _computed.gt)("users.length", 0),
    didReceiveAttrs() {
      this._super(...arguments);
      if (this.replyChannel?.name !== this.replyChannelName) {
        this.replyChannel?.unsubscribe();
        this.set("replyChannel", this.presence.getChannel(this.replyChannelName));
        this.replyChannel.subscribe();
      }
      if (this.currentUser.staff && this.whisperChannel?.name !== this.whisperChannelName) {
        this.whisperChannel?.unsubscribe();
        this.set("whisperChannel", this.presence.getChannel(this.whisperChannelName));
        this.whisperChannel.subscribe();
      }
    },
    _destroyed() {
      this.replyChannel?.unsubscribe();
      this.whisperChannel?.unsubscribe();
    }
  }, (_applyDecoratedDescriptor(_obj, "replyUsers", [_dec], Object.getOwnPropertyDescriptor(_obj, "replyUsers"), _obj), _applyDecoratedDescriptor(_obj, "whisperUsers", [_dec2], Object.getOwnPropertyDescriptor(_obj, "whisperUsers"), _obj), _applyDecoratedDescriptor(_obj, "replyChannelName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "replyChannelName"), _obj), _applyDecoratedDescriptor(_obj, "whisperChannelName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "whisperChannelName"), _obj), _applyDecoratedDescriptor(_obj, "_destroyed", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_destroyed"), _obj)), _obj)));
});